import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Typography,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect, Link } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { green } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PrintBill from "./PrintBill";
import EInvoice from "./EInvoice";
import Deliverynote from "./Deliverynote";
import { darken, lighten } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Rejected": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Parts": {
    backgroundColor: "", // Change to light blue
    "&:hover": {
      backgroundColor: "", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "", // Change to light blue
      "&:hover": {
        backgroundColor: "", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Lubricant": {
    backgroundColor: "#FFFF99", // Change to light blue
    "&:hover": {
      backgroundColor: "#FFFF99", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "#FFFF99", // Change to light blue
      "&:hover": {
        backgroundColor: "#FFFF99", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Battery": {
    backgroundColor: "#FAD5A5", // Change to light blue
    "&:hover": {
      backgroundColor: "#FAD5A5", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "#FAD5A5", // Change to light blue
      "&:hover": {
        backgroundColor: "#FAD5A5", // Change to light blue
      },
    },
  },
  "& .super-app-theme--TyreTube": {
    backgroundColor: " #d9dad7",
    "&:hover": {
      backgroundColor: " #d9dad7", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: " #d9dad7", // Change to light blue
      "&:hover": {
        backgroundColor: " #d9dad7", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Accessories": {
    backgroundColor: "lightblue", // Change to light blue
    "&:hover": {
      backgroundColor: "lightblue", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "lightblue", // Change to light blue
      "&:hover": {
        backgroundColor: "lightblue", // Change to light blue
      },
    },
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "2px solid red", // Change this to the desired color
    backgroundColor: "gray",
    color: "white",
  },
}));

///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const SaleBill = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disabledprintbtn, setdisabledprintbtn] = useState(true);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   LocalstorageHelper.RemoveUserToken();
  // }, 3600000);
  const [altparts, setaltparts] = useState([]);
  // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  const quantityRef = useRef(null);
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
      }
    }
    if (event.code === "F2") {
      if (event.target === quantityRef.current) {
        try {
          const response = await fetch(`/altitemstock/${partno.itemid}`, {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          });
          const jsonData = await response.json();
          setaltparts(jsonData);
          //console.log(altparts);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  }
  function handleSwitch(opt) {
    setpartno({
      deleteditem: opt.deleteditem,
      gstrate: opt.gstrate,
      itemid: opt.alternateitemid,
      mrp: opt.mrp,
      partdetails: opt.itemcode + "-" + opt.itemname,
      purchaseqty: opt.closingqty,
      rackname: opt.rackname,
      salerate: opt.salerate,
      spcldiscount: opt.spcldiscount,
    });
    setstock(opt.closingqty);
  }

  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //  GET Party Search Parameters  AND HOLD STATE OF SEARCH INPUT//
  const [partyInput, setPartyInput] = useState({
    address1: "",
    address2: "",
    address3: "",
    batterydiscrate: "",
    destinationid: "",
    salesmanid: "",
    salesmandetails: "",
    lubdiscrate: "",
    partsdiscrate: "",
    partydetails: "",
    stateid: "",
    statename: "",
    subledgerid: "",
  });
  //  console.log(partyInput);
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/spare/salebill/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //GET JOBCARD NOS
  const [jobcardInput, setjobcardInput] = useState(null);
  const [jobcardfetch, setjobcardfetch] = useState(false);
  const [jobcardopts, setjobcardOpts] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [newModePartNo, setNewModePartNo] = useState("");
  const newPartNoRef = useRef(null);
  const handlePartnoKey = async (event) => {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === newPartNoRef.current) {
        const body = { code: newModePartNo };
        // const data = allparts.find((opt) => opt.itemcode === newModePartNo);
        const apicall = await fetch("/allparts/detailfetch", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await apicall.json();
        if (data.itemid) {
          setpartno(data);
          setstock(data.purchaseqty);
          setDelItem(data.deleteditem);
          setaltFlag(data.altflag);
          if (state.saletype !== 3 && quantityRef.current) {
            quantityRef.current.focus();
          }

          // Prevent the default Tab behavior
          event.preventDefault();
        } else {
          toast.error("No Part Found!");
          setNewModePartNo("");
          newPartNoRef.current.focus();
          event.preventDefault();
        }
      }
    }
  };
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  async function getJobCardOpts() {
    try {
      const response = await fetch("/spare/salebill/jobcards", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setjobcardOpts(jsonData);
      setjobcardfetch(false);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (jobcardfetch === true) {
      getJobCardOpts();
    }
  }, [jobcardfetch]);

  // FROM JOB CARD CUSTOMER DETAIL FETCH BUTTON HANDLER

  const [jcCustomerarray, setjcCustomerarray] = useState([]);
  const [jobDate, setjobDate] = useState(null);
  const [regno, setregno] = useState("");
  const handleJcCustomerFetch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/salebill/customerdetails/${jobcardInput.jobcardid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setjcCustomerarray(jsonData);
      setState((prevState) => ({
        ...prevState,
        statename: jsonData.statename,
        address1: jsonData.address1,
        address2: jsonData.address2,
        address3: jsonData.address3,
        partsdisc: jsonData.partsdiscrate,
        lubrdisc: jsonData.lubdiscrate,
        batterydisc: jsonData.batterydiscrate,
      }));
      setjobDate(jsonData.jobdate);
      setregno(jsonData.regno);
      setPartyInput({
        subledgerid: `${jsonData.subledgerid}`,
        partydetails: `${jsonData.partydetails}`,
        stateid: `${jsonData.stateid}`,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  // PARTY FETCH BUTTON CLICK
  const [fetchedOrderArray, setFetchedOrderArray] = useState([]);
  const handlePartyFetch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/salebill/orders/${partyInput.subledgerid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setFetchedOrderArray(jsonData);
      setState((prevState) => ({
        ...prevState,
        statename: partyInput.statename,
        address1: partyInput.address1,
        address2: partyInput.address2,
        address3: partyInput.address3,
        partsdisc: partyInput.partsdiscrate,
        lubrdisc: partyInput.lubdiscrate,
        batterydisc: partyInput.batterydiscrate,
      }));
      setSalesmanInput({
        salesmandetails: `${partyInput.salesmandetails}`,
        salesmanid: `${partyInput.salesmanid}`,
      });
      setpendingdisabled(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET MAKER-PICKER Search Parameters  AND HOLD STATE OF SEARCH INPUT//
  const [searchInput, setSearchInput] = useState(null);
  //console.log(searchInput);
  const [editmodedata, seteditmodedata] = useState([]);
  //console.log("emodedata",editmodedata);
  const [searchOpts, setsearchOpts] = useState([]);
  const [searchFetch, setsearchFetch] = useState(false);
  const [mkpkInput, setMkpkInput] = useState(null);
  const [pickerInput, setPickerInput] = useState(null);
  const [mkpkopts, setMkpkOpts] = useState([]);

  async function getMkpkOpts() {
    try {
      const response = await fetch("/spare/salebill/mkpk", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setMkpkOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getMkpkOpts();
  }, []);

  //  GET SALESMAN Search Parameters  AND HOLD STATE OF SEARCH INPUT//

  const [salesmanInput, setSalesmanInput] = useState(null);
  const [salesmanopts, setSalesmanOpts] = useState([]);
  const [irn, setirn] = useState("");
  async function getSalesmanOpts() {
    try {
      const response = await fetch("/spare/salebill/salesman", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setSalesmanOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getSalesmanOpts();
  }, []);

  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  //   CLICKING ON PARTY SEARCH EXECUTES THE FOLLOWING    //
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/salebill/salebillopts/${searchInput.billheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      seteditmodedata(jsonData);
      // console.log(jsonData);
      setirn(jsonData[0].irn);
      setState({
        salecategory: parseInt(jsonData[0].salecatg),
        saletype: parseInt(jsonData[0].saletype),
        sorttype: jsonData[0].sorton,
        invoiceno: jsonData[0].billno,
        statename: jsonData[0].statename,
        address1: jsonData[0].address1,
        address2: jsonData[0].address2,
        address3: jsonData[0].address3,
        balance: jsonData[0].balancecheck,
        partsdisc: jsonData[0].partsdiscrate,
        lubrdisc: jsonData[0].lubdiscrate,
        batterydisc: jsonData[0].batterydiscrate,
        labourdisc: jsonData[0].labourdiscrate,
      });
      setinvoicedate(jsonData[0].billdate);
      setPartyInput({
        subledgerid: `${jsonData[0].subledgerid}`,
        partydetails: `${jsonData[0].partydetails}`,
        stateid: `${jsonData[0].stateid}`,
      });
      setMkpkInput({
        mkpkdetails: `${jsonData[0].mkdetails}`,
        makerpickerid: `${jsonData[0].makerid}`,
      });
      setPickerInput({
        mkpkdetails: `${jsonData[0].pkdetails}`,
        makerpickerid: `${jsonData[0].pickid}`,
      });
      setSalesmanInput({
        salesmandetails: `${jsonData[0].salesmandetails}`,
        salesmanid: `${jsonData[0].salesmanid}`,
      });
      setjobcardInput({
        searchdetails: `${jsonData[0].searchdetails}`,
        jobcardid: `${jsonData[0].jobcardid}`,
      });
      setjobDate(jsonData[0].jobdate);
      setregno(jsonData[0].regno);
      setpartybtndisabled(true);
      setshipingaddflag(jsonData[0].sameshippingflag);
      setshpname(jsonData[0].shpname);
      setshpgstno(jsonData[0].shpgstno);
      setshpaddrs1(jsonData[0].shpaddrs1);
      setshpaddrs2(jsonData[0].shpaddrs2);
      setshppincode(jsonData[0].shppincode);
      setshplocation({
        destid: jsonData[0].destid,
        destname: jsonData[0].destname,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  //TEXTFIELD STATES AND CHANGE HANDLER
  const [state, setState] = useState({
    salecategory: 1,
    saletype: 1,
    sorttype: "Rack",
    invoiceno: "",
    statename: "",
    address1: "",
    address2: "",
    address3: "",
    balance: "N",
    partsdisc: "",
    lubrdisc: "",
    batterydisc: "",
    labourdisc: "0",
  });
  const date = new Date();
  const [invoicedate, setinvoicedate] = useState(date);
  //console.log(invoicedate);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "salecategory" && value === 2) {
      setjobcardfetch(true);
      setMkpkInput(
        mkpkopts.find((opt) => opt.mkpkdetails.split("-")[1] === "NONE")
      );
      setPickerInput(
        mkpkopts.find((opt) => opt.mkpkdetails.split("-")[1] === "NONE")
      );
      setSalesmanInput(
        salesmanopts.find((opt) => opt.salesmandetails.split("-")[1] === "NONE")
      );
      setpartydisabled(true);
      setOrderDetailRow([]);
      setState({
        salecategory: 1,
        saletype: 2,
        sorttype: "Rack",
        invoiceno: "",
        statename: "",
        address1: "",
        address2: "",
        address3: "",
        balance: "N",
        partsdisc: "",
        lubrdisc: "",
        batterydisc: "",
        labourdisc: "0",
      });
      setpartybtndisabled(true);
    }
    if (name === "salecategory" && value === 1) {
      setjobcardfetch(false);
      setpartydisabled(false);
      setOrderDetailRow([]);
      setMkpkInput(null);
      setPickerInput(null);
      setSalesmanInput(null);
      setState({
        salecategory: 1,
        saletype: 1,
        sorttype: "Rack",
        invoiceno: "",
        statename: "",
        address1: "",
        address2: "",
        address3: "",
        balance: "N",
        partsdisc: "",
        lubrdisc: "",
        batterydisc: "",
        labourdisc: "0",
      });
      setpartybtndisabled(false);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteClick = (id) => () => {
    if (window.confirm("Are you sure you want to remove the item?") === true) {
      setOrderDetailRow(orderdetailrow.filter((row) => row.itemid !== id));
    }
  };
  const updateMrp = (id) => async () => {
    const index = orderdetailrow.findIndex((row) => row.itemid === id);
    //console.log(orderdetailrow[index].itemid);
    try {
      const response = await fetch(
        `/spare/salebill/mrpup/${orderdetailrow[index].itemid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();

      const updatedRow = {
        ...orderdetailrow[index],
        mrp1: jsonData.mrp1,
        mrp2: jsonData.mrp2,
        mrp3: jsonData.mrp3,
        mrp4: jsonData.mrp4,
        closingqty: jsonData.stkqty,
      };
      const updatedArray = [
        ...orderdetailrow.slice(0, index),
        updatedRow,
        ...orderdetailrow.slice(index + 1),
      ];
      setOrderDetailRow(updatedArray);
      toast.success("Current Stock and new MRP Synced!", { autoClose: 2000 });
    } catch (err) {
      console.log(err.message);
    }
  };
  const updateMrpEmode = (id) => async () => {
    const index = editmodedata.findIndex((row) => row.itemid === id);
    //console.log(editmodedata[index].itemid);
    try {
      const response = await fetch(
        `/spare/salebill/mrpup/${editmodedata[index].itemid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();

      const updatedRow = {
        ...editmodedata[index],
        mrp1: jsonData.mrp1,
        mrp2: jsonData.mrp2,
        mrp3: jsonData.mrp3,
        mrp4: jsonData.mrp4,
        closingqty: jsonData.stkqty,
      };
      const updatedArray = [
        ...editmodedata.slice(0, index),
        updatedRow,
        ...editmodedata.slice(index + 1),
      ];
      seteditmodedata(updatedArray);
      toast.success("Current Stock and new MRP Synced!", { autoClose: 2000 });
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleemodeDeleteClick = (id) => () => {
    if (window.confirm("Are you sure you want to remove the item?") === true) {
      seteditmodedata(editmodedata.filter((row) => row.itemid !== id));
    }
  };
  // DISABLED STATE OF PART DETAILS AND ITS BUTTON

  const [partydisabled, setpartydisabled] = useState(false);
  const [partybtndisabled, setpartybtndisabled] = useState(false);

  /// PENDING CUSTOMER ORDER DISABLED STATE
  const [pendingdisabled, setpendingdisabled] = useState(true);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Dele"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 60,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Part No.",
      width: 200,
      cellClassName: "custom-font-size",
      valueGetter: (params) => {
        const itemdetails = params.row.partdetails;
        const partno = itemdetails.split("---");
        return partno[0];
      },
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 200,
      cellClassName: "custom-font-size",
      valueGetter: (params) => {
        const itemdetails = params.row.partdetails;
        const partno = itemdetails.split("---");
        return partno[1];
      },
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    // {
    //   field: "partdetails",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Part Details",
    //   width: 300,
    //   cellClassName: "custom-font-size",
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
    //   ),
    // },
    {
      field: "updatemrp",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Fetch Updated Values",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<SyncIcon />}
            label="Update"
            onClick={updateMrp(id)}
            color="inherit"
          />,
        ];
      },
    },

    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Sale Qty",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.saleqty;
      },
      valueSetter: (params) => {
        if (parseFloat(params.value) < 0 || parseFloat(params.value) === 0) {
          toast.error("Can't Be Negative Or Zero!", {
            toastId: "error1",
          });
        } else if (
          parseFloat(params.row.closingqty) < parseFloat(params.value)
        ) {
          toast.error("Can't Be More Than Stock!", {
            toastId: "error1",
          });
        } else {
          // console.log("I reach else");
          const saleqty = params.value;
          const value = parseFloat(params.value * params.row.salerate).toFixed(2);
          const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
            2
          );
          const taxable = parseFloat(
            parseFloat(value) - parseFloat(disamt)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxable * params.row.gstpers) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(taxable) + parseFloat(gstamt)
          ).toFixed(2);
          let upd_obj;
          if (parseInt(state.salecategory) === 2) {
            // console.log("salecatg 2");
            upd_obj = orderdetailrow.findIndex(
              (obj) => parseInt(obj.itemid) === parseInt(params.row.itemid)
            );
          } else if (parseInt(state.salecategory) === 1) {
            // console.log("salecatg 1");
            // console.log(orderdetailrow);
            upd_obj = orderdetailrow.findIndex(
              (obj) => parseInt(obj.serial) === parseInt(params.row.serial)
            );
          }
          // const upd_obj =
          //   parseInt(state.salecategory) === 2
          //     ? orderdetailrow.findIndex(
          //         (obj) => parseInt(obj.itemid) === parseInt(params.row.itemid)
          //       )
          //     : orderdetailrow.findIndex(
          //         (obj) => obj.billdetailid === params.row.billdetailid
          //       );
          // console.log(upd_obj);
          // console.log(orderdetailrow[upd_obj]);
          orderdetailrow[upd_obj].saleqty = saleqty;
          orderdetailrow[upd_obj].value = value;
          orderdetailrow[upd_obj].disamt = disamt;
          orderdetailrow[upd_obj].taxable = taxable;
          orderdetailrow[upd_obj].gstamt = gstamt;
          orderdetailrow[upd_obj].netamt = netamt;
          return {
            ...params.row,
            saleqty,
            value,
            disamt,
            taxable,
            gstamt,
            netamt,
          };
        }
      },
    },
    // {
    //   field: "boxno",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "BoxNo.",
    //   width: 60,
    //   cellClassName: "custom-font-size",
    // },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      type: "number",
      width: 100,
      editable: true,
      valueGetter: (params) => {
        return params.row.mrp;
      },
      valueSetter: (params) => {
        if (
          state.saletype !== 3 &&
          parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp4)
        ) {
          toast.error("Enter A Valid MRP!", {
            toastId: "error1",
          });
        } else {
          const mrp = params.value;
          const salerate = parseFloat(
            (parseFloat(params.value) * 100) /
              (100 + parseFloat(params.row.gstpers))
          ).toFixed(2);
          const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
          const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
            2
          );
          const taxable = parseFloat(
            parseFloat(value) - parseFloat(disamt)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxable * params.row.gstpers) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(taxable) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj =
            parseInt(state.salecategory) === 2
              ? orderdetailrow.findIndex(
                  (obj) => obj.itemid === params.row.itemid
                )
              : orderdetailrow.findIndex(
                  (obj) => obj.serial === params.row.serial
                );
          // console.log(`orderdetailrow`, orderdetailrow);
          // console.log(`update obj:`, upd_obj);
          orderdetailrow[upd_obj].mrp = mrp;
          orderdetailrow[upd_obj].salerate = salerate;
          orderdetailrow[upd_obj].value = value;
          orderdetailrow[upd_obj].disamt = disamt;
          orderdetailrow[upd_obj].taxable = taxable;
          orderdetailrow[upd_obj].gstamt = gstamt;
          orderdetailrow[upd_obj].netamt = netamt;
          return {
            ...params.row,
            mrp,
            salerate,
            value,
            disamt,
            taxable,
            gstamt,
            netamt,
          };
        }
      },
    },
    {
      field: "dispers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis %",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.dispers;
      },
      valueSetter: (params) => {
        // console.log("I reach else");
        const dispers = params.value;
        const value = params.row.value;
        const disamt = parseFloat((value * dispers) / 100).toFixed(2);
        const taxable = parseFloat(
          parseFloat(value) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
          2
        );
        const netamt = parseFloat(
          parseFloat(taxable) + parseFloat(gstamt)
        ).toFixed(2);
        let upd_obj;
        if (parseInt(state.salecategory) === 2) {
          // console.log("salecatg 2");
          upd_obj = orderdetailrow.findIndex(
            (obj) => parseInt(obj.itemid) === parseInt(params.row.itemid)
          );
        } else if (parseInt(state.salecategory) === 1) {
          // console.log("salecatg 1");
          // console.log(orderdetailrow);
          upd_obj = orderdetailrow.findIndex(
            (obj) => parseInt(obj.serial) === parseInt(params.row.serial)
          );
        }
        // const upd_obj =
        //   parseInt(state.salecategory) === 2
        //     ? orderdetailrow.findIndex(
        //         (obj) => parseInt(obj.itemid) === parseInt(params.row.itemid)
        //       )
        //     : orderdetailrow.findIndex(
        //         (obj) => obj.billdetailid === params.row.billdetailid
        //       );
        // console.log(upd_obj);
        // console.log(orderdetailrow[upd_obj]);
        orderdetailrow[upd_obj].dispers = dispers;
        orderdetailrow[upd_obj].disamt = disamt;
        orderdetailrow[upd_obj].taxable = taxable;
        orderdetailrow[upd_obj].gstamt = gstamt;
        orderdetailrow[upd_obj].netamt = netamt;
        return {
          ...params.row,
          dispers,
          disamt,
          taxable,
          gstamt,
          netamt,
        };
      },
    },
    {
      field: "closingqty",
      headerClassName: "super-app-theme--header",
      headerName: "Current Stock",
      type: "number",
      width: 100,
    },

    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "Current MRP",
      type: "number",
      width: 100,
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      type: "number",
      width: 100,
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      type: "number",
      width: 100,
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      type: "number",
      width: 100,
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST %",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 100,
      type: "number",
    },
    {
      field: "disamt",
      headerClassName: "super-app-theme--header",
      headerName: "Dis Amt",
      width: 80,
      type: "number",
    },
    {
      field: "taxable",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "cgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "CGST Amt",
      width: 80,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "sgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "SGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },

    {
      field: "igstamt",
      headerClassName: "super-app-theme--header",
      headerName: "IGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) !== 1) {
          return parseFloat(params.row.gstamt).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },

    // {
    //   field: "action",
    //   type: "actions",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Delete",
    //   width: 100,
    //   cellClassName: "actions",
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];
  const emodecolumns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Dele",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Dele"
            onClick={handleemodeDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 20,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Part No.",
      width: 200,
      cellClassName: "custom-font-size",
      valueGetter: (params) => {
        const itemdetails = params.row.partdetails;
        const partno = itemdetails.split("---");
        return partno[0];
      },
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 200,
      cellClassName: "custom-font-size",
      valueGetter: (params) => {
        const itemdetails = params.row.partdetails;
        const partno = itemdetails.split("---");
        return partno[1];
      },
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    // {
    //   field: "partdetails",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Part Details",
    //   width: 300,
    //   cellClassName: "custom-font-size",
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
    //   ),
    // },
    {
      field: "updatemrp",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Fetch Updated Values",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<SyncIcon />}
            label="Update"
            onClick={updateMrpEmode(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "SaleQty",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.saleqty;
      },
      valueSetter: (params) => {
        console.log(params);
        if (parseFloat(params.value) > parseFloat(params.row.bufferstock)) {
          toast.error("Sale Quantity Should Not Exceed Buffer Stock!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else {
          const saleqty = params.value;
          const grossvalue = parseFloat(saleqty * params.row.salerate).toFixed(
            2
          );
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 100).toFixed(2)
              : 0;
          const netvalue = parseFloat(
            parseFloat(taxablevalue) +
              parseFloat(cgstvalue) +
              parseFloat(sgstvalue) +
              parseFloat(igstvalue)
          ).toFixed(2);
          const upd_obj = editmodedata.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          // console.log(upd_obj);
          // console.log(editmodedata[upd_obj]);
          editmodedata[upd_obj].saleqty = saleqty;
          editmodedata[upd_obj].grossvalue = grossvalue;
          editmodedata[upd_obj].discountvalue = discountvalue;
          editmodedata[upd_obj].taxablevalue = taxablevalue;
          editmodedata[upd_obj].cgstvalue = cgstvalue;
          editmodedata[upd_obj].sgstvalue = sgstvalue;
          editmodedata[upd_obj].igstvalue = igstvalue;
          editmodedata[upd_obj].netvalue = netvalue;
          console.log("returned Row:", {
            ...params.row,
            saleqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          });
          return {
            ...params.row,
            saleqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    // {
    //   field: "boxno",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "BoxNo.",
    //   width: 60,
    //   cellClassName: "custom-font-size",
    // },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      type: "number",
      width: 80,
      editable: true,
      valueGetter: (params) => {
        return params.row.mrp;
      },
      valueSetter: (params) => {
        if (
          state.saletype !== 3 &&
          parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp4)
        ) {
          toast.error("Enter A Valid MRP!", {
            toastId: "error1",
          });
        } else {
          const mrp = params.value;
          const salerate = parseFloat(
            (parseFloat(params.value) * 100) /
              (100 + parseFloat(params.row.gstrate))
          ).toFixed(2);
          const grossvalue = parseFloat(params.row.saleqty * salerate).toFixed(
            2
          );
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = editmodedata.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          editmodedata[upd_obj].mrp = mrp;
          editmodedata[upd_obj].salerate = salerate;
          editmodedata[upd_obj].grossvalue = grossvalue;
          editmodedata[upd_obj].discountvalue = discountvalue;
          editmodedata[upd_obj].taxablevalue = taxablevalue;
          editmodedata[upd_obj].gstamt = gstamt;
          editmodedata[upd_obj].netvalue = netvalue;
          return {
            ...params.row,
            mrp,
            salerate,
            grossvalue,
            discountvalue,
            taxablevalue,
            gstamt,
            netvalue,
          };
        }
      },
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 80,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.discountrate;
      },
      valueSetter: (params) => {
        const discountrate = params.value;
        const grossvalue = params.row.grossvalue;
        const discountvalue = parseFloat(
          (grossvalue * discountrate) / 100
        ).toFixed(2);
        const taxablevalue = parseFloat(
          parseFloat(grossvalue) - parseFloat(discountvalue)
        ).toFixed(2);
        const cgstvalue =
          parseInt(partyInput.stateid) === 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
            : 0;
        const sgstvalue =
          parseInt(partyInput.stateid) === 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
            : 0;
        const igstvalue =
          parseInt(partyInput.stateid) !== 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 100).toFixed(2)
            : 0;
        const netvalue = parseFloat(
          parseFloat(taxablevalue) +
            parseFloat(cgstvalue) +
            parseFloat(sgstvalue) +
            parseFloat(igstvalue)
        ).toFixed(2);
        const upd_obj = editmodedata.findIndex(
          (obj) => obj.itemid === params.row.itemid
        );
        editmodedata[upd_obj].discountrate = discountrate;
        editmodedata[upd_obj].discountvalue = discountvalue;
        editmodedata[upd_obj].taxablevalue = taxablevalue;
        editmodedata[upd_obj].cgstvalue = cgstvalue;
        editmodedata[upd_obj].sgstvalue = sgstvalue;
        editmodedata[upd_obj].igstvalue = igstvalue;
        editmodedata[upd_obj].netvalue = netvalue;
        return {
          ...params.row,
          discountrate,
          discountvalue,
          taxablevalue,
          cgstvalue,
          sgstvalue,
          igstvalue,
          netvalue,
        };
      },
    },
    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "CurrentMRP",
      type: "number",
      width: 100,
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      type: "number",
      width: 80,
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      type: "number",
      width: 80,
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      type: "number",
      width: 80,
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "grossvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 80,
      type: "number",
    },
    {
      field: "discountvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Dis Amt",
      width: 80,
      type: "number",
    },
    {
      field: "taxablevalue",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },

    {
      field: "cgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "CGSTAmt",
      width: 80,
      type: "number",
    },
    {
      field: "sgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "SGSTAmt",
      width: 80,
      type: "number",
    },

    {
      field: "igstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "IGSTAmt",
      width: 100,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(params.row.igstvalue) === 0) {
          return parseFloat(params.row.cgstvalue * 2).toFixed(2);
        }
        if (parseInt(params.row.igstvalue) !== 0) {
          return parseFloat(params.row.igstvalue).toFixed(2);
        }
      },
    },
    {
      field: "netvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
    {
      field: "bufferstock",
      headerClassName: "super-app-theme--header",
      headerName: "Buffer Stock",
      width: 100,
      type: "number",
    },
  ];
  // SETUP ARRAY FOR INSERTION OF NEW SALE BILL
  const [salebill, setsalebill] = useState([]);
  const salebillarray = Object.values(salebill);
  console.log("salebillarray", salebillarray);
  // ADD ROW ON CLICK
  // NEED CHANGES IN CGST SGST
  const handleaddrow = () => {
    if (parseInt(itemqty) < 0) {
      return toast.error("Negative Value Not Allowed!");
    }
    if (parseInt(itemqty) > parseInt(partno.purchaseqty)) {
      return toast.error("Error: Item quantity should not exceed stock!");
    }
    if (state.saletype === 3 && itemqty > 1) {
      return toast.error("Only qty 1 allowed for labour type!");
    }
    if (
      (state.salecategory === 2 && itemqty === 0) ||
      (state.salecategory === 2 && itemqty === null)
    ) {
      return toast.error("Quantity Cannot Be Empty Or Zero!!");
    }
    if (
      parseInt(mode) === 1
        ? orderdetailrow.map((e) => e.itemid).includes(partno.itemid)
        : editmodedata.map((e) => e.itemid).includes(partno.itemid)
    ) {
      toast.error("Item Added Already!");
    } else {
      if (parseInt(mode) === 1) {
        if (state.saletype === 3) {
          const discountamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(salerate) *
                  parseFloat(state.labourdisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(salerate) *
                  parseFloat(state.labourdisc)) /
                100;
          const valueamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(salerate)
              : parseInt(partno.purchaseqty) * parseFloat(salerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const newmrp = (
            parseFloat((salerate * partno.gstrate) / 100) + parseFloat(salerate)
          ).toFixed(2);
          const netamount = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setOrderDetailRow((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: newmrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstpers: partno.gstrate,
              salerate: parseFloat(salerate).toFixed(2),
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: state.labourdisc,
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: parseFloat(netamount).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          const discountamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(state.labourdisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(state.labourdisc)) /
                100;
          const valueamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamount = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setOrderDetailRow((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstpers: partno.gstrate,
              salerate: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: state.labourdisc,
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: parseFloat(netamount).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      } else {
        if (state.saletype === 3) {
          const discountvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(salerate) *
                  parseFloat(editmodedata[0].discountrate)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(salerate) *
                  parseFloat(editmodedata[0].discountrate)) /
                100;
          const grossvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(salerate)
              : parseInt(partno.purchaseqty) * parseFloat(salerate);
          const taxablevalue = parseFloat(grossvalue - discountvalue);
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100) / 2
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100)
              : 0;
          const newmrp = (
            parseFloat((salerate * partno.gstrate) / 100) + parseFloat(salerate)
          ).toFixed(2);
          seteditmodedata((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: newmrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstrate: partno.gstrate,
              salerate: parseFloat(salerate).toFixed(2),
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              discountrate: editmodedata[0].discountrate,
              grossvalue: parseFloat(grossvalue).toFixed(2),
              discountvalue: parseFloat(discountvalue).toFixed(2),
              taxablevalue: parseFloat(taxablevalue).toFixed(2),
              cgstvalue: parseFloat(cgstvalue).toFixed(2),
              sgstvalue: parseFloat(cgstvalue).toFixed(2),
              igstvalue: parseFloat(igstvalue).toFixed(2),
              netvalue: parseFloat(
                parseFloat(taxablevalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(igstvalue)
              ).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          const discountvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(editmodedata[0].discountrate)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(editmodedata[0].discountrate)) /
                100;
          const grossvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const taxablevalue = parseFloat(grossvalue - discountvalue);
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100) / 2
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100)
              : 0;
          seteditmodedata((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstrate: partno.gstrate,
              salerate: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              discountrate: editmodedata[0].discountrate,
              grossvalue: parseFloat(grossvalue).toFixed(2),
              discountvalue: parseFloat(discountvalue).toFixed(2),
              taxablevalue: parseFloat(taxablevalue).toFixed(2),
              cgstvalue: parseFloat(cgstvalue).toFixed(2),
              sgstvalue: parseFloat(cgstvalue).toFixed(2),
              igstvalue: parseFloat(igstvalue).toFixed(2),
              netvalue: parseFloat(
                parseFloat(taxablevalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(igstvalue)
              ).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      }
    }
  };

  // Search parts
  const [partno, setpartno] = useState(null);
  const [stock, setstock] = useState(0);
  const [salerate, setsalerate] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState(null);
  const [allparts, setallparts] = useState([]);
  useEffect(() => {
    if (state.salecategory === 2 && state.saletype === 3) {
      setitemqty(1);
    } else {
      setitemqty(null);
    }
  }, [state.saletype]);

  useEffect(() => {
    if (partno && state.saletype !== 3) {
      quantityRef.current.focus();
    }
  }, [partno]);
  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  async function getalllabours() {
    try {
      const response = await fetch("/labour/alllabours", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (state.salecategory === 2 && state.saletype === 3 && isSwitchOn) {
      getalllabours();
    } else if (isSwitchOn) {
      getallparts();
    }
  }, [state.saletype, isSwitchOn]);

  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [totalvalue, setTotalvalue] = useState(0);
  const [totalitems, setTotalitems] = useState(0);
  const [totaldisamt, setTotaldisamt] = useState(0);
  const [totaltaxamt, setTotaltaxamt] = useState(0);
  const [totalcgst, setTotalcgst] = useState(0);
  const [totalsgst, setTotalsgst] = useState(0);
  const [totaligst, setTotaligst] = useState(0);
  const [totalgstamt, setTotalgstamt] = useState(0);
  const [totalnetamt, setTotalnetamt] = useState(0);
  const [delvcharge, setDelvcharge] = useState(0);
  const [shipingaddflag, setshipingaddflag] = useState(true);
  const [shpname, setshpname] = useState("");
  const [shpgstno, setshpgstno] = useState("");
  const [shpaddrs1, setshpaddrs1] = useState("");
  const [shpaddrs2, setshpaddrs2] = useState("");
  const [shppincode, setshppincode] = useState(null);
  const [shplocation, setshplocation] = useState(null);
  const [shplocationopts, setshplocationopts] = useState([]);

  let billAmt = Math.round(parseFloat(totalnetamt) + parseFloat(delvcharge));

  let roundOff = parseFloat(
    parseFloat(billAmt) - (parseFloat(totalnetamt) + parseFloat(delvcharge))
  ).toFixed(2);

  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotalvalue, setnewTotalvalue] = useState(0);
  const [newtotalitems, setnewTotalitems] = useState(0);
  const [newtotaldisamt, setnewTotaldisamt] = useState(0);
  const [newtotaltaxamt, setnewTotaltaxamt] = useState(0);
  const [newtotalcgst, setnewTotalcgst] = useState(0);
  const [newtotalsgst, setnewTotalsgst] = useState(0);
  const [newtotaligst, setnewTotaligst] = useState(0);
  const [newtotalgstamt, setnewTotalgstamt] = useState(0);
  const [newtotalnetamt, setnewTotalnetamt] = useState(0);
  const [newdelvcharge, setNewDelvCharge] = useState(0);
  let newbillAmt = Math.round(
    parseFloat(newtotalnetamt) + parseFloat(newdelvcharge)
  );
  let newroundOff = parseFloat(
    parseFloat(newbillAmt) -
      (parseFloat(newtotalnetamt) + parseFloat(newdelvcharge))
  ).toFixed(2);
  ///EDIT MODE GRID STORE ARRAY
  const [editmodelookupobj, seteditmodelookupobj] = useState([]);
  const editedDetails = Object.values(editmodelookupobj);
  console.log("editedDetails:", editedDetails);
  // SUBMIT CUSTOMER ORDER DETAILS

  //fetch area/location
  async function getAreaOpts() {
    try {
      const response = await fetch("/areaopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setshplocationopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAreaOpts();
  }, []);

  const handleSaleBillSubmit = async (e) => {
    console.log("hit");
    e.preventDefault();
    if (mkpkInput === null || pickerInput === null) {
      return toast.error("Please enter Maker Picker!!");
    }
    if (billAmt === 0) {
      return toast.error("Bill Amount Is 0!!");
    }
    setDisableSubmit(true);
    setdisabledprintbtn(false);
    if (parseInt(state.salecategory) === 1) {
      try {
        const body = {
          state,
          partyInput,
          mkpkInput,
          pickerInput,
          salesmanInput,
          salebillarray,
          totalcgst,
          totalsgst,
          totalvalue,
          totaldisamt,
          totaltaxamt,
          totaligst,
          totalgstamt,
          delvcharge,
          roundOff,
          billAmt,
          invoicedate,
          shipingaddflag,
          shpname,
          shpgstno,
          shpaddrs1,
          shpaddrs2,
          shppincode,
          shpdestid: shplocation !== null ? shplocation.destid : null,
        };
        const response = await fetch("/spare/salebill/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });

        const parseRes = await response.json();
        // console.log(parseRes);
        if (parseRes.billno !== undefined) {
          toast.success(`Bill No ${parseRes.billno} Created Successfully`, {
            autoClose: false,
            onClose: () => window.location.reload(false),
          });
        } else {
          toast.error(`${parseRes.message}`, {
            autoClose: 3000,
          });
          setDisableSubmit(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    if (parseInt(state.salecategory) === 2) {
      try {
        const body = {
          state,
          partyInput,
          mkpkInput,
          pickerInput,
          salesmanInput,
          salebillarray,
          totalcgst,
          totalsgst,
          totalvalue,
          totaldisamt,
          totaltaxamt,
          totaligst,
          totalgstamt,
          delvcharge,
          roundOff,
          billAmt,
          invoicedate,
          jobcardInput,
          jobDate,
          regno,
          shipingaddflag,
          shpname,
          shpgstno,
          shpaddrs1,
          shpaddrs2,
          shppincode,
          shpdestid: shplocation !== null ? shplocation.destid : null,
        };
        const response = await fetch("/spare/salebill/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });

        const parseRes = await response.json();
        parseRes.billno !== undefined
          ? toast.success(`Bill No ${parseRes.billno} Created Successfully`, {
              autoClose: false,
              onClose: () => window.location.reload(false),
            })
          : toast.error(`${parseRes.message}`, {
              autoClose: 3000,
            });
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };
  /// SUBMIT EDITMODE DETAILS
  const [editdisable, seteditdisable] = useState(false);
  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    if (newbillAmt === 0) {
      return toast.error("Bill Amount Is 0!!");
    }
    seteditdisable(true);
    try {
      const body = {
        editedDetails,
        newtotalcgst,
        newtotalsgst,
        newtotaligst,
        newtotalgstamt,
        newtotaldisamt,
        newtotaltaxamt,
        newtotalvalue,
        partyInput,
        mkpkInput,
        pickerInput,
        salesmanInput,
        state,
        invoicedate,
        newdelvcharge,
        newroundOff,
        newbillAmt,
        shipingaddflag,
        shpname,
        shpgstno,
        shpaddrs1,
        shpaddrs2,
        shppincode,
        shpdestid: shplocation.destid,
      };
      const response = await fetch(
        `/spare/salebill/up/${searchInput.billheaderid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
        seteditdisable(false);
      }
    } catch (err) {
      console.log(err.message);
      seteditdisable(false);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);

  // CONTROLS FOR DIFFERENT MODES

  const [mode, setMode] = useState(1);
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
    {
      value: 3,
      label: "View",
    },
  ];

  const billmodes = [
    {
      value: 1,
      label: "Against Order",
    },
    {
      value: 2,
      label: "Against Job Card",
    },
    // {
    //   value: 3,
    //   label: "Direct",
    // },
  ];

  // DROPDOWN VALUES AND HANDLER
  const saletypeopts = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Spare",
    },
    {
      value: 3,
      label: "Labour",
    },
  ];

  const sortopts = [
    {
      value: "Rack",
      label: "Rack",
    },
    {
      value: "Item",
      label: "Item",
    },
  ];
  const balanceopts = [
    {
      value: "Y",
      label: "Yes",
    },
    {
      value: "N",
      label: "No",
    },
  ];

  //----------- Modal Handler-------

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //shipping details modal
  const [shpopen, setshpOpen] = useState(false);
  const handleshpOpen = () => setshpOpen(true);
  const handleshpClose = () => {
    setshpOpen(false);
  };
  //

  // HANDLE CHECKED ORDER ARRAY

  const [orderarray, setorderarray] = useState([]);
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setorderarray([...orderarray, e.target.value]);
    } else {
      setorderarray(orderarray.filter((o) => o !== e.target.value));
    }
  };

  //HANDLE ORDER DETAILS FETCH
  const [orderdetailrow, setOrderDetailRow] = useState([]);
  //console.log("orderdetailrow",orderdetailrow);
  // useEffect(() => {
  //   if (orderdetailrow.length > 0) {
  //     const checkdisabled = orderdetailrow.some(
  //       (opt) => parseFloat(opt.closingqty) < parseFloat(opt.saleqty)
  //     );
  //     if (checkdisabled) {
  //       setDisableSubmit(true);
  //       setdisabledprintbtn(false);
  //     } else {
  //       setDisableSubmit(false);
  //       setdisabledprintbtn(true);
  //     }
  //   }
  // }, [orderdetailrow]);
  // console.log(orderdetailrow);
  const handleorderdetails = async (e) => {
    e.preventDefault();
    const body = { orderarray, state };
    try {
      const response = await fetch("/spare/salebill/orderdetails", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const jsonData = await response.json();
      // console.log(jsonData);
      setOrderDetailRow(jsonData);
      setorderarray([]);
    } catch (err) {
      console.log(err.message);
    }
  };
  const [billType, setBillType] = useState(1);
  // console.log(newbillAmt, newtotalnetamt);
  async function getSearchOpts() {
    try {
      const response = await fetch(
        `/spare/salebill/salebillopts/fetch/${billType}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setsearchOpts(jsonData);
      // setsearchFetch(false);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  //for view
  async function getViewOpts() {
    try {
      const response = await fetch(
        `/spare/salebill/salebillopts/fetch/view/${billType}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setsearchOpts(jsonData);
      // setsearchFetch(false);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (parseInt(mode) === 2) {
      getSearchOpts();
    } else if (parseInt(mode) === 3) {
      getViewOpts();
    }
  }, [mode, billType]);

  const handleRecalculate = () => {
    if (
      (parseFloat(state.partsdisc) > 0 &&
        (parseFloat(state.lubrdisc) > 0 ||
          parseFloat(state.batterydisc) > 0 ||
          parseFloat(state.labourdisc) > 0)) ||
      (parseFloat(state.lubrdisc) > 0 &&
        (parseFloat(state.batterydisc) > 0 ||
          parseFloat(state.labourdisc) > 0)) ||
      (parseFloat(state.batterydisc) > 0 && parseFloat(state.labourdisc) > 0)
    ) {
      return toast.error("Can't give more than 1 discount type!");
    }
    if (parseInt(mode) === 1) {
      const updatedRows = orderdetailrow.map((row) => {
        const discpers =
          parseFloat(state.partsdisc) +
          parseFloat(state.labourdisc) +
          parseFloat(state.lubrdisc) +
          parseFloat(state.batterydisc);
        const disamt = ((parseFloat(row.value) * discpers) / 100).toFixed(2);
        const taxable = (parseFloat(row.value) - parseFloat(disamt)).toFixed(2);
        const gstamt = (
          (parseFloat(taxable) * parseFloat(row.gstpers)) /
          100
        ).toFixed(2);
        const cgstamt = gstamt / 2;
        const sgstamt = gstamt / 2;
        const igstamt = 0.0;
        const netamt = (parseFloat(taxable) + parseFloat(gstamt)).toFixed(2);
        return { ...row, dispers: discpers, disamt, taxable, gstamt, netamt };
      });
      setOrderDetailRow(updatedRows);
    } else {
      const updatedRows = editmodedata.map((row) => {
        const discpers =
          parseFloat(state.partsdisc) +
          parseFloat(state.labourdisc) +
          parseFloat(state.lubrdisc) +
          parseFloat(state.batterydisc);
        const disamt = ((parseFloat(row.grossvalue) * discpers) / 100).toFixed(
          2
        );
        const taxable = (
          parseFloat(row.grossvalue) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = (
          (parseFloat(taxable) * parseFloat(row.gstrate)) /
          100
        ).toFixed(2);
        const cgstamt = gstamt / 2;
        const sgstamt = gstamt / 2;
        const igstamt = 0.0;
        const netamt = (parseFloat(taxable) + parseFloat(gstamt)).toFixed(2);
        return {
          ...row,
          discountrate: discpers,
          discountvalue: disamt,
          taxablevalue: taxable,
          cgstvalue: cgstamt,
          sgstvalue: sgstamt,
          igstvalue: 0,
          netvalue: netamt,
        };
      });
      seteditmodedata(updatedRows);
    }
  };

  const resetStates = () => {
    seteditmodedata([]);
    setpartno(null);
    setitemqty(0);
    setinvoicedate(null);
    setSearchInput(null);
    setMkpkInput(null);
    setPickerInput(null);
    setSalesmanInput(null);
    setPartyInput(null);
    setState((prevState) => ({
      ...prevState,
      statename: "",
      address1: "",
      address2: "",
      address3: "",
      partsdisc: "",
      lubrdisc: "",
      batterydisc: "",
      invoiceno: "",
    }));
    // setCalculateBillAmt(0);
  };
  return (
    <div>
      <Sidebar
        Title={"SALE BILL"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={1} mb={"10px"} direction="row">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={1}
                  name="radio-buttons-group"
                  row
                >
                  {saletypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={
                        <Radio
                          size="small"
                          disabled={parseInt(mode) === 1 ? true : false}
                        />
                      }
                      label={opt.label}
                      onChange={(e) => setBillType(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={searchOpts}
                getOptionLabel={(option) => option.salebilldetails}
                sx={{
                  width: 420,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search Party"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                variant="contained"
                key="one"
                sx={{ height: "40px" }}
                onClick={handleSearch}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="billMode"
                required
                select
                label="Bill Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2 || e.target.value === 3) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setsearchFetch(true);
                    resetStates();
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setsearchFetch(false);
                    resetStates();
                  }
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <EInvoice />
              <Deliverynote />
              <Link to="/deliverynoteview">
                <Button>Generated Delivery Notes→</Button>
              </Link>
              <Link to="/ewaybill">
                <Button>eWay Bill Print→</Button>
              </Link>
              <Link to="/testreport">
                <Button>Sale Bill Print→</Button>
              </Link>
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="againstSale"
                required
                select
                label="Against Sale"
                name="salecategory"
                value={state.salecategory}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {billmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                <TextField
                  id="saleType"
                  required
                  select
                  label="Sale Type"
                  name="saletype"
                  value={state.saletype}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: "60%",
                  }}
                  size="small"
                >
                  {saletypeopts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="sortType"
                  required
                  name="sorttype"
                  select
                  label="Sort By"
                  value={state.sorttype}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: "40%",
                  }}
                  size="small"
                >
                  {sortopts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <TextField
                id="invoiceNo"
                label="Invoice No"
                name="invoiceno"
                value={state.invoiceno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  disabled
                  inputFormat="DD/MM/YYYY"
                  value={invoicedate}
                  onChange={(newValue) => {
                    setinvoicedate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                <Autocomplete
                  disabled={partydisabled}
                  id="partysearch"
                  value={partyInput}
                  filterOptions={filterOptions2}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setPartyInput({
                        address1: "",
                        address2: "",
                        address3: "",
                        batterydiscrate: "",
                        destinationid: "",
                        salesmanid: "",
                        salesmandetails: "",
                        lubdiscrate: "",
                        partsdiscrate: "",
                        partydetails: "",
                        stateid: "",
                        statename: "",
                        subledgerid: "",
                      });
                    } else {
                      setPartyInput(newValue);
                    }
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.partydetails}
                  sx={{
                    width: "85% ",
                  }}
                  renderInput={(billopts) => (
                    <TextField
                      required
                      {...billopts}
                      placeholder="Party Details"
                    />
                  )}
                  size="small"
                />
                <Button
                  aria-label="search"
                  variant="outlined"
                  disabled={partybtndisabled}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#673AB7",
                    },
                    backgroundColor: "purple",
                    height: "100%",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  onClick={handlePartyFetch}
                >
                  <BillSearch sx={{ color: "white" }} />
                </Button>
              </Stack>
              <TextField
                id="stateName"
                required
                label="State Name"
                name="statename"
                value={state.statename}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address1"
                required
                label="Address 1"
                value={state.address1}
                name="address1"
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address2"
                label="Address 2"
                value={state.address2}
                name="address2"
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="address3"
                label="Address 3"
                value={state.address3}
                name="address3"
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <Autocomplete
                id="makersearch"
                value={mkpkInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setMkpkInput(newValue);
                }}
                options={mkpkopts}
                getOptionLabel={(option) => option.mkpkdetails}
                isOptionEqualToValue={(option, value) =>
                  option.makerpickerid === value.makerpickerid
                }
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Maker" />
                )}
                size="small"
              />
              <Autocomplete
                id="pickersearch"
                value={pickerInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setPickerInput(newValue);
                }}
                options={mkpkopts}
                getOptionLabel={(option) => option.mkpkdetails}
                sx={{
                  width: "20% ",
                }}
                isOptionEqualToValue={(option, value) =>
                  option.makerpickerid === value.makerpickerid
                }
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Picker" />
                )}
                size="small"
              />
              <Autocomplete
                id="salesmansearch"
                value={salesmanInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setSalesmanInput(newValue);
                }}
                options={salesmanopts}
                getOptionLabel={(option) => option.salesmandetails}
                isOptionEqualToValue={(option, value) =>
                  option.salesmanid === value.salesmanid
                }
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Salesman" />
                )}
                size="small"
              />
            </Stack>
            {parseInt(state.salecategory) === 2 ? (
              <Stack
                spacing={2}
                columnGap={6}
                direction="row"
                mt={2}
                ml={4}
                mr={4}
              >
                <Accordion sx={{ width: "100%", backgroundColor: "#d0deec" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: "#a4c2e0" }}
                  >
                    <Typography>Job Card Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      spacing={2}
                      columnGap={6}
                      direction="row"
                      ml={4}
                      mr={4}
                      mt={1}
                    >
                      <Stack spacing={1} sx={{ width: "30%" }} direction="row">
                        <Autocomplete
                          id="jobnosearch"
                          value={jobcardInput}
                          filterOptions={filterOptions2}
                          onChange={(event, newValue) => {
                            setjobcardInput(newValue);
                          }}
                          options={jobcardopts}
                          getOptionLabel={(option) => option.searchdetails}
                          sx={{
                            width: "100% ",
                            backgroundColor: "white",
                          }}
                          renderInput={(billopts) => (
                            <TextField
                              required
                              {...billopts}
                              placeholder="Job No"
                            />
                          )}
                          size="small"
                        />
                        <Button
                          aria-label="search"
                          //type="search"
                          variant="outlined"
                          sx={{
                            "&:hover": {
                              backgroundColor: "#673AB7",
                            },
                            backgroundColor: "purple",
                            height: "100%",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                          onClick={handleJcCustomerFetch}
                        >
                          <BillSearch sx={{ color: "white" }} />
                        </Button>
                      </Stack>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Job Date"
                          inputFormat="DD/MM/YYYY"
                          value={jobDate}
                          onChange={(newValue) => {
                            setjobDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              sx={{
                                width: "30%",
                                backgroundColor: "white",
                              }}
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <TextField
                        id="regNo"
                        label="Reg No"
                        value={regno}
                        type="text"
                        sx={{
                          width: "30%",
                          backgroundColor: "white",
                        }}
                        size="small"
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            ) : (
              <></>
            )}
            {parseInt(state.salecategory) === 1 ? (
              <Stack
                spacing={2}
                columnGap={2}
                direction="row"
                mt={1}
                ml={4}
                mr={4}
              >
                <Accordion
                  sx={{ width: "100%", backgroundColor: "#d0deec" }}
                  disabled={pendingdisabled}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: "#a4c2e0" }}
                  >
                    <Typography>
                      Customer Pending Order List
                      {fetchedOrderArray.length > 0 ? (
                        <Chip
                          label={`${fetchedOrderArray.length} pending`}
                          sx={{ fontWeight: "bold", color: "red", mx: 2 }}
                        />
                      ) : (
                        <Chip
                          label="no orders found"
                          sx={{ fontWeight: "bold" }}
                        />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ height: "350px", overflowY: "scroll" }}
                  >
                    <Stack direction="row">
                      <Table
                        sx={{
                          minWidth: "100%",
                          boxShadow: "2px 6px 15px #5a5a5a",
                        }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead
                          sx={{
                            backgroundColor: "#3bb9ff",
                            border: "0.5px solid black",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Order No
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            ></TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Order Id
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {fetchedOrderArray.length > 0 ? (
                          <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                            {fetchedOrderArray.map((opt) => (
                              <TableRow key={opt.corderheaderid}>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.orderno}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  <Checkbox
                                    sx={{
                                      color: green[800],
                                      transform: "scale(1.5)",
                                      "&.Mui-checked": {
                                        color: green[600],
                                      },
                                    }}
                                    key={opt.corderheaderid}
                                    value={opt.corderheaderid}
                                    onChange={handleCheckbox}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.orderdate}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.corderheaderid}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <Typography variant="subtitle1" ml={10}>
                            NO ITEMS FOUND
                          </Typography>
                        )}
                      </Table>
                    </Stack>
                    <Stack columnGap={1} direction="row" m={4} ml={"45%"}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="success"
                        // type="submit"
                        onClick={handleorderdetails}
                      >
                        OK
                      </Button>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            ) : (
              <></>
            )}
            {parseInt(state.salecategory) === 3 ? <Stack></Stack> : <></>}
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="balance"
                required
                select
                label="Balance"
                name="balance"
                value={state.balance}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {balanceopts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {parseInt(mode) === 3 &&
                (irn !== null ? (
                  <Typography display={irn === "" ? "none" : "block"}>
                    {" "}
                    {`Generated IRN ${irn}`}
                  </Typography>
                ) : (
                  <Typography>
                    IRN not available or yet to be generated!!
                  </Typography>
                ))}

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={parseInt(mode) === 2 || parseInt(mode) === 3}
                    checked={shipingaddflag}
                    onChange={(e) => setshipingaddflag(e.target.checked)}
                  />
                }
                label="Shipping Address Same As Address"
              />
              <Button
                disabled={shipingaddflag}
                onClick={handleshpOpen}
                color="success"
                size="small"
                variant="contained"
                sx={{ borderRadius: 20 }}
              >
                Shipping Details
              </Button>
              <Modal
                open={shpopen}
                aria-labelledby="modl-box"
                aria-describedby="search partitem"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "35%",
                    left: "58%",
                    transform: "translate(-50%, -50%)",
                    width: 950,
                    bgcolor: "background.paper",
                    border: "2px solid #C0C0C0",
                    borderRadius: "5px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    "& button": { m: 1 },
                  }}
                >
                  <Stack
                    columnGap={2}
                    direction="row"
                    component="form"
                    sx={{
                      width: 950,
                      p: 2,
                    }}
                    autoComplete="off"
                  >
                    <TextField
                      id="shpname"
                      label="ShippingName"
                      value={shpname}
                      onChange={(e) => setshpname(e.target.value)}
                      sx={{
                        width: 300,
                      }}
                      size="small"
                    />
                    <TextField
                      id="ShpGstno"
                      label="ShippingGstno"
                      value={shpgstno}
                      onChange={(e) => setshpgstno(e.target.value)}
                      sx={{
                        width: 400,
                      }}
                      size="small"
                    />
                    <Autocomplete
                      id="ShpLoc"
                      value={shplocation}
                      filterOptions={filterOptions}
                      onChange={(event, newValue) => {
                        setshplocation(newValue);
                      }}
                      options={shplocationopts}
                      getOptionLabel={(option) => option.destname}
                      sx={{
                        width: "30% ",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="ShippingLocation"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    columnGap={2}
                    direction="row"
                    component="form"
                    sx={{
                      width: 950,
                      p: 2,
                    }}
                    autoComplete="off"
                  >
                    <TextField
                      id="ShippingAddrs1"
                      label="ShippingAddrs1"
                      value={shpaddrs1}
                      onChange={(e) => setshpaddrs1(e.target.value)}
                      sx={{
                        width: 500,
                      }}
                      size="small"
                    />
                    <TextField
                      id="ShippingAddrs2"
                      label="ShippingAddrs2"
                      value={shpaddrs2}
                      onChange={(e) => setshpaddrs2(e.target.value)}
                      sx={{
                        width: 500,
                      }}
                      size="small"
                    />
                    <TextField
                      id="ShippingPincode"
                      label="ShippingPincode"
                      value={shppincode}
                      onChange={(e) => setshppincode(e.target.value)}
                      sx={{
                        width: 250,
                      }}
                      size="small"
                    />
                    <Button color="error" onClick={handleshpClose}>
                      Close
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <TextField
                id="partsDisc"
                label="Parts Disc %"
                value={state.partsdisc}
                type="text"
                name="partsdisc"
                onChange={state.salecategory === 2 ? handleChange : () => {}}
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="lubydisc"
                label="Lubr Disc %"
                name="lubrdisc"
                value={state.lubrdisc}
                onChange={state.salecategory === 2 ? handleChange : () => {}}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="batteryDisc"
                label="Battery Disc %"
                name="batterydisc"
                value={state.batterydisc}
                onChange={state.salecategory === 2 ? handleChange : () => {}}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="labourDisc"
                label="Labour Disc %"
                name="labourdisc"
                value={state.labourdisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              {state.salecategory === 2 && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleRecalculate}
                >
                  Recalculate
                </Button>
              )}
            </Stack>

            <hr />
            {parseInt(state.salecategory) !== 1 ? (
              <Stack columnGap={3} direction="row">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Old Mode</Typography>
                  <Switch
                    onChange={handleSwitchChange}
                    checked={isSwitchOn}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography>New Mode</Typography>
                </Stack>
                <Button
                  sx={{ ml: "3%", mt: "13px" }}
                  variant="outlined"
                  size="small"
                  onClick={handleOpen}
                  startIcon={<AddCircleOutline />}
                >
                  ADD A NEW ROW
                </Button>

                <Modal
                  open={open}
                  aria-labelledby="modl-box"
                  aria-describedby="search partitem"
                >
                  <Box sx={style}>
                    <Stack
                      columnGap={2}
                      direction="row"
                      component="form"
                      sx={{
                        width: 750,
                        p: 2,
                      }}
                      autoComplete="off"
                    >
                      {!isSwitchOn && (
                        <TextField
                          id="partno"
                          label="Part No"
                          value={newModePartNo}
                          onChange={(e) => setNewModePartNo(e.target.value)}
                          inputRef={newPartNoRef}
                          onKeyDown={handlePartnoKey}
                          sx={{
                            width: "200px",
                          }}
                          size="small"
                        />
                      )}
                      {isSwitchOn && (
                        <Autocomplete
                          id="partno"
                          value={partno}
                          filterOptions={filterOptions}
                          onChange={(event, newValue) => {
                            setpartno(newValue);
                            setstock(newValue.purchaseqty);
                            setDelItem(newValue.deleteditem);
                            setaltFlag(newValue.altflag);
                          }}
                          options={allparts}
                          getOptionLabel={(option) => option.partdetails}
                          sx={{
                            width: "30% ",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Part No"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                      {state.saletype === 3 && (
                        <TextField
                          id="salerate"
                          label="Sale Rate"
                          value={salerate}
                          onChange={(e) => setsalerate(e.target.value)}
                          type="number"
                          sx={{
                            width: "30%",
                          }}
                          size="small"
                        />
                      )}
                      <TextField
                        id="quantity"
                        label="Quantity"
                        value={itemqty}
                        onChange={(e) => setitemqty(e.target.value)}
                        inputRef={quantityRef}
                        onKeyDown={handleKeyDown}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                      />

                      <TextField
                        id="stock"
                        label="Stock"
                        value={stock}
                        type="number"
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                    </Stack>
                    <Stack
                      columnGap={2}
                      direction="row"
                      component="form"
                      sx={{
                        width: 750,
                        p: 2,
                      }}
                      autoComplete="off"
                    >
                      <TextField
                        id="delitem"
                        label="Deleted Item"
                        value={delItem}
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="altitem"
                        label="Alternate Item"
                        value={altFlag}
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />

                      <Button
                        variant="contained"
                        tabIndex={-1}
                        size="medium"
                        color="success"
                        onClick={handleaddrow}
                      >
                        ADD
                      </Button>
                      <Button
                        variant="contained"
                        tabIndex={-1}
                        size="medium"
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                    <Stack
                      columnGap={2}
                      direction="row"
                      component="form"
                      sx={{
                        width: 750,
                        p: 2,
                      }}
                      autoComplete="off"
                    >
                      <Table
                        sx={{
                          minWidth: "100%",
                          boxShadow: "2px 6px 15px #5a5a5a",
                        }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead
                          sx={{
                            backgroundColor: "#3bb9ff",
                            border: "0.5px solid black",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Alt-Item Name
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Alt-Item Number
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Alt-Item Stock
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                              }}
                              align="center"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {altparts.length > 0 ? (
                          <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                            {altparts.map((opt) => (
                              <TableRow key={opt.alternateitemid}>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.itemname}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.itemcode}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  {opt.closingqty}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                >
                                  <Button onClick={(e) => handleSwitch(opt, e)}>
                                    Switch
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <Typography variant="subtitle1" ml={10}>
                            NO ITEMS FOUND
                          </Typography>
                        )}
                      </Table>
                    </Stack>
                  </Box>
                </Modal>
              </Stack>
            ) : (
              <></>
            )}

            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mr={3}
            >
              <TextField
                id="delvcharg"
                label=" + Delv Charge"
                value={parseInt(mode) === 1 ? delvcharge : newdelvcharge}
                onChange={(e) =>
                  parseInt(mode) === 1
                    ? setDelvcharge(e.target.value)
                    : setNewDelvCharge(e.target.value)
                }
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              <TextField
                id="roundoff"
                label="Round Off"
                value={parseInt(mode) === 1 ? roundOff : newroundOff}
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              <TextField
                id="billamt"
                label="Bill Amount"
                value={parseInt(mode) === 1 ? billAmt : newbillAmt}
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              {orderdetailrow.length > 0 && (
                <>
                  <Chip
                    label="Parts"
                    size="small"
                    sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
                  />
                  <Chip
                    label="Lubricant"
                    size="small"
                    sx={{
                      backgroundColor: "#FFFF99",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Battery"
                    size="small"
                    sx={{
                      backgroundColor: "#FAD5A5",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Tyre & Tube"
                    size="small"
                    sx={{
                      backgroundColor: " #d9dad7",
                      //color: "white",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Accessries"
                    size="small"
                    sx={{
                      backgroundColor: "lightblue",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                </>
              )}
            </Stack>
            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <StyledDataGrid
                  sx={{ border: "1px solid #000" }}
                  rows={orderdetailrow}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  pageSize={100}
                  columns={columns}
                  getRowId={(row) => row.itemid}
                  getRowClassName={(params) => {
                    if (
                      parseFloat(params.row.closingqty) <
                      parseFloat(params.row.saleqty)
                    ) {
                      return "super-app-theme--Rejected";
                    } else if (params.row.groupid === "1") {
                      return "super-app-theme--Parts";
                    } else if (params.row.groupid === "2") {
                      return "super-app-theme--Lubricant";
                    } else if (params.row.groupid === "3") {
                      return "super-app-theme--Battery";
                    } else if (params.row.groupid === "4") {
                      return "super-app-theme--TyreTube";
                    } else if (params.row.groupid === "5") {
                      return "super-app-theme--Accessories";
                    } else {
                      return "";
                    }
                  }}
                  onStateChange={(params) => {
                    setsalebill(params.rows.idRowsLookup);
                    // console.log(salebillarray);
                    const itemtotal = salebillarray
                      .map((item) => parseFloat(item.saleqty))
                      .reduce((a, b) => a + b, 0);
                    setTotalitems(parseFloat(itemtotal).toFixed(2));
                    const valuetotal = salebillarray
                      .map((item) => parseFloat(item.value))
                      .reduce((a, b) => a + b, 0);
                    setTotalvalue(parseFloat(valuetotal).toFixed(2));

                    const distotal = salebillarray
                      .map((item) => parseFloat(item.disamt))
                      .reduce((a, b) => a + b, 0);
                    setTotaldisamt(parseFloat(distotal).toFixed(2));
                    const taxtotal = salebillarray
                      .map((item) => parseFloat(item.taxable))
                      .reduce((a, b) => a + b, 0);
                    setTotaltaxamt(parseFloat(taxtotal).toFixed(2));

                    const cgsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt / 2))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) === 1
                      ? setTotalcgst(parseFloat(cgsttotal).toFixed(2))
                      : setTotalcgst(0);
                    const sgsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt / 2))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) === 1
                      ? setTotalsgst(parseFloat(sgsttotal).toFixed(2))
                      : setTotalsgst(0);
                    const igsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) !== 1
                      ? setTotaligst(parseFloat(igsttotal).toFixed(2))
                      : setTotaligst(0);
                    const gsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setTotalgstamt(parseFloat(gsttotal).toFixed(2));

                    const nettotal = salebillarray
                      .map(
                        (item) =>
                          parseFloat(item.gstamt) + parseFloat(item.taxable)
                      )
                      .reduce((a, b) => a + b, 0);
                    setTotalnetamt(parseFloat(nettotal).toFixed(2));
                  }}
                />
                <Box sx={{ padding: "10px", height: 110 }}>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Items
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Dis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Tot Taxable Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            CGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            SGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            IGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Tot GST Amt
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalitems}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {totaldisamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totaltaxamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalcgst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalsgst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totaligst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totalgstamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  justifyContent={"center"}
                >
                  <Button
                    // type="submit"
                    onClick={handleSaleBillSubmit}
                    disabled={billAmt > 0 ? disableSubmit : true}
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    SAVE BILL
                  </Button>
                  <Button
                    // type="submit"
                    //onClick={handleEditmodeSubmit}
                    variant="contained"
                    color="warning"
                    size="small"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    RESET BILL
                  </Button>
                  <PrintBill
                    state={state}
                    partyInput={partyInput}
                    mkpkInput={mkpkInput}
                    pickerInput={pickerInput}
                    salesmanInput={salesmanInput}
                    salebillarray={salebillarray}
                    totalcgst={totalcgst}
                    totalsgst={totalsgst}
                    totalvalue={totalvalue}
                    totaldisamt={totaldisamt}
                    totaltaxamt={totaltaxamt}
                    totaligst={totaligst}
                    totalgstamt={totalgstamt}
                    delvcharge={delvcharge}
                    roundOff={roundOff}
                    billAmt={billAmt}
                    invoicedate={invoicedate}
                    jobcardInput={jobcardInput}
                    jobDate={jobDate}
                    regno={regno}
                    disabledprintbtn={disabledprintbtn}
                    setdisabledprintbtn={setdisabledprintbtn}
                    setDisableSubmit={setDisableSubmit}
                    shipingaddflag={shipingaddflag}
                    shpname={shpname}
                    shpgstno={shpgstno}
                    shpaddrs1={shpaddrs1}
                    shpaddrs2={shpaddrs2}
                    shppincode={shppincode}
                    shplocation={shplocation}
                  />
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 || parseInt(mode) === 3 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <DataGrid
                  sx={{
                    border: "1px solid #000",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  rows={editmodedata}
                  columns={emodecolumns}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    seteditmodelookupobj(params.rows.idRowsLookup);
                    const itemtotal = editedDetails
                      .map((item) => parseFloat(item.saleqty))
                      .reduce((a, b) => a + b, 0);
                    setnewTotalitems(parseFloat(itemtotal).toFixed(2));
                    const valuetotal = editedDetails
                      .map((item) => parseFloat(item.grossvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotalvalue(parseFloat(valuetotal).toFixed(2));

                    const distotal = editedDetails
                      .map((item) => parseFloat(item.discountvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotaldisamt(parseFloat(distotal).toFixed(2));
                    const taxtotal = editedDetails
                      .map((item) => parseFloat(item.taxablevalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotaltaxamt(parseFloat(taxtotal).toFixed(2));

                    const cgsttotal = editedDetails
                      .map((item) => parseFloat(item.cgstvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotalcgst(parseFloat(cgsttotal).toFixed(2));
                    const sgsttotal = editedDetails
                      .map((item) => parseFloat(item.sgstvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotalsgst(parseFloat(sgsttotal).toFixed(2));
                    const igsttotal = editedDetails
                      .map((item) => parseFloat(item.igstvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotaligst(parseFloat(igsttotal).toFixed(2));
                    const gsttotal =
                      parseFloat(igsttotal) +
                      parseFloat(cgsttotal) +
                      parseFloat(sgsttotal);
                    setnewTotalgstamt(parseFloat(gsttotal).toFixed(2));

                    const nettotal = editedDetails
                      .map((item) => parseFloat(item.netvalue))
                      .reduce((a, b) => a + b, 0);
                    setnewTotalnetamt(parseFloat(nettotal).toFixed(2));
                  }}
                />
                <Box sx={{ padding: "10px", height: 110 }}>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Items
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Dis Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Tot Taxable Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            CGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            SGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            IGST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Tot GST Amt
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalitems}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotaldisamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotaltaxamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalcgst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalsgst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotaligst}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotalgstamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>
                {parseInt(mode) === 2 && (
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    mt={6}
                    pb={6}
                    mx={"35%"}
                  >
                    <Button
                      disabled={editdisable}
                      // type="submit"
                      onClick={handleEditmodeSubmit}
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    >
                      UPDATE BILL
                    </Button>
                    <Button
                      // type="submit"
                      // onClick={handleEditmodeSubmit}
                      variant="contained"
                      color="warning"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    >
                      RESET BILL
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SaleBill;
